<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">{{ i18n('profile.title') }}</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5">
          <!-- <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer">
            <div>
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                data-action="zoom"
                :src="model.avatar ? model.avatar : '/images/default-avatar.svg'"
              />
                :src="require(`@/assets/images/${$f()[0].photos[0]}`)"
              <div class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-31 rounded-full p-2">
                <CameraIcon class="w-4 h-4 text-white" />
              </div>
            </div>

            <input
              type="file" id="profileImage" name="upload"
              accept="image/png, image/jpeg, image/jpg"
              style="position: absolute; top:0%; opacity:0 !important"
              class="cursor-pointer w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative m-auto cursor-pointer"
              @input="emitEvent($event)"
            />
          </div> -->

          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:bg-dark-9"
                v-model="model.fullName"
                :placeholder="i18n('iam.fields.fullName')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="text"
                class="form-control w-full dark:bg-dark-9"
                v-model="model.email"
                readonly
                :placeholder="i18n('iam.fields.emailAddress')"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-5">
            <div class="lg:col-span-4"></div>
            <div class="lg:col-span-4 col-span-12">
              <input
                id="crud-form-1"
                type="number"
                class="form-control w-full dark:bg-dark-9"
                v-model="model.phoneNumber"
                :placeholder="i18n('iam.fields.phoneNumber')"
              />
            </div>
          </div>
          <div class="flex flex-col sm:flex-row justify-center items-center gap-2 mt-5 sm:mt-10">
            <AppButton
              type="button"
              class="btn bg-theme-31 text-white cursor-pointer"
              :class="!saveLoading && !uploadLoading ? 'w-full sm:w-24' : ''"
              :disabled="saveLoading || uploadLoading"
              :loading="saveLoading || uploadLoading"
              @click="doSubmit()"
            >
              <strong>{{ i18n('common.save') }}</strong>
              <template v-slot:loading>
                <app-i18n code="common.loading"></app-i18n>
                <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
              </template>
            </AppButton>
            <button type="button" class="btn bg-gray-200 text-black w-full sm:w-40" :disabled="saveLoading || uploadLoading" @click="goToChangePassword()">
              {{ i18n('profile.changePassword') }}
            </button>
            <button type="button" class="btn bg-theme-32 text-black w-full sm:w-24" :disabled="saveLoading || uploadLoading" @click="doCancel()">
              {{ i18n('common.cancel') }}
            </button>
          </div>
          <!-- <div class="mt-3">
            <MultipleFileUpload />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { FileUploader } from '@/shared/uploader/file-uploader'
// import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { UserModel } from '@/store/user/user-model'

const { fields } = UserModel
const formSchema = new FormSchema([
  fields.fullName,
  fields.phoneNumber,
  fields.email
  // fields.avatar
])

export default defineComponent({
  setup() {
    const image = ref(null)
    const imageUrl = ref('')

    return {
      image,
      imageUrl
    }
  },
  data() {
    return {
      uploadLoading: false,
      model: null,
      rules: formSchema.rules(),
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      saveLoading: 'auth/loadingUpdateProfile'
    }),
    fields() {
      return fields
    }
  },
  created() {
    document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.profile')
    this.model = formSchema.initialValues(this.currentUser)
  },
  watch: {
    isRTL() {
      document.title = this.i18n('app.title') + ' | ' + this.i18n('routes.profile')
    }
  },
  methods: {
    ...mapActions({
      doUpdateProfile: 'auth/doUpdateProfile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      for (const key in this.model) {
        const value = this.model[key]
        if (value) {
          this.errorMessage = 'profile.form.errors.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.push('/')
    },
    goToChangePassword() {
      this.$router.push('/change-password')
    },
    emitEvent(event) {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = e => {
        const image = {
          name: event.target.files[0].name,
          size: event.target.files[0].size,
          lastModifiedDate: event.target.files[0].lastModifiedDate,
          base64: reader.result
        }
        this.image = image
        const file = event.target.files[0]
        this.imageUrl = URL.createObjectURL(file)
        this.model.avatar = this.imageUrl
      }
    },
    doReset() {
      this.model = formSchema.initialValues(this.currentUser)
    },
    async doSubmit() {
      try {
        // if (!this.isFormValid()) {
        //   const ERROR = this.i18n(this.errorMessage)
        //   return Message.error(ERROR)
        // }

        if (this.image) {
          this.uploadLoading = true
          const profileImage = document.getElementById('profileImage').files[0]
          const path = `user/avatars/profile/${this.currentUser.id}`
          const { publicUrl } = await FileUploader.upload(path, profileImage, 'avatar')
          this.model.avatar = publicUrl
        }

        const values = formSchema.cast(this.model)
        await this.doUpdateProfile(values)
        this.uploadLoading = false
      } catch (error) {
        console.log(error)
      }
    }
  }
})
</script>
